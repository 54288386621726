import request from '@/js/request'
// 获取错误模具列表
export function getCustomer (data) {
  return request({
    url: '/customer',
    method: 'get',
    params: data
  })
}

export function addCompany (data, param, lang) {
  return request({
    url: '/customer/create?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}

export function editCompany (data, param, lang) {
  return request({
    url: '/customer/update?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}

// 删除用户
export function deleteCompany (data, param, lang) {
  return request({
    url: '/customer/remove?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}
// 获取序列号
export function getSerial (data) {
  return request({
    url: '/company',
    method: 'get',
    params: data
  })
}

// 新增序列号
export function addSerial (data, param, lang) {
  return request({
    url: '/company/authorize?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}

// 删除序列号
export function deleteSerial (data, param, lang) {
  return request({
    url: '/company/cancel?token=' + param + '&language=' + lang,
    method: 'post',
    data: data
  })
}
