<template>
  <div class="main">
    <div class="app-wrapper">
      <div class="machine_title">{{ $t("Machine.Equipment") }}</div>
      <div class="machine_search">
        <div class="machine_search_list">
          <div class="search_title">{{ $t("Custom.Serial") }}</div>
          <div class="search_selected">
            <el-input :label="$t('AddMachine.GroupSimple')" v-model.trim="groupName"
              :placeholder="$t('AddMachine.inputSerialNumber')" clearable />
          </div>
        </div>
        <div class="machine_search_btn">
          <div class="search_btn" @click="searchGroup" :style="{ background: isBlueSearch ? '#3660DD' : '#4875ef' }">
            {{ $t("Machine.Search") }}
          </div>
        </div>
        <div class="machine_search_btn">
          <div class="search_btn" @click="reset" :style="{ background: isBlue ? '#3660DD' : '#4875ef' }">
            {{ $t("Warning.Reset") }}
          </div>
        </div>
        <div class="machine_search_space"></div>
      </div>
      <div class="table_content">
        <el-table ref="multipleTable" :data="tableData" @row-click="rowClick" tooltip-effect="dark"
          :header-cell-style="{ background: '#dde0e8' }" highlight-current-row style="width: 100%" row-key="id"
          :expand-row-keys="expandRowKeys" @expand-change="expandChange">
          <el-table-column width="10" align="center" />
          <el-table-column type="expand" min-width="10%">
            <template slot-scope="props">
              <el-table ref="multipleTable" :data="props.row.authorities" tooltip-effect="dark" style="width: 100%"
                :header-cell-style="{ background: '#dde0e8' }">
                <el-table-column width="70" align="center" />
                <el-table-column :label="$t('Custom.Order')" min-width="15%" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div>{{ scope.$index + 1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Custom.Number')" prop="number" min-width="15%" show-overflow-tooltip />
                <el-table-column :label="$t('Machine.Serial')" prop="serial_number" min-width="15%"
                  show-overflow-tooltip />
                <el-table-column min-width="15%" :label="$t('Warning.FrontVersion')" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div>{{ scope.row.view_update_version }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="15%" :label="$t('Warning.ServiceVersion')" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div>{{ scope.row.service_update_version }}</div>
                  </template>
                </el-table-column>
                <el-table-column min-width="15%" :label="$t('Setting.CreationTime')" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div>{{ scope.row.createtime | time }}</div>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('AddMachine.Operation')" width="120" align="left">
                  <template slot-scope="scope">
                    <img src="../../assets/image/bianji_main@2x.png" @click="handleEdit(scope.$index, scope.row)"
                      class="OperationImg mgr" />
                    <img src="../../assets/image/shanchu_mian@2x.png" @click="handleDeleteOrder(scope.row)"
                      class="OperationImg mgr" />
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column min-width="15%" :label="$t('Custom.Company')" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>

          <el-table-column l:label="$t('AddMachine.Operation')" width="80" align="left">
            <template slot-scope="scope">
              <img src="../../assets/image/add.png" @click="handleAdd(scope.row)" class="OperationImg" />
            </template>
          </el-table-column>

          <el-table-column width="20" align="center" />
        </el-table>
      </div>
      <div class="pagination-container">
        <el-pagination background layout="prev, pager, next, sizes" :page-size="pagesize" :page-sizes="[5, 10, 15]"
          :current-page.sync="page" :total="total" v-show="total && total > 0" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
    <el-dialog :title="$t(title)" :visible.sync="dialogVisible" width="364px" class="dialog">
      <el-form ref="workShopForm" :model="Serial" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Machine.Serial") }}
            </div>
            <el-input :disabled="type == 'edit'" v-model.trim="Serial.name"
              :placeholder="$t('AddMachine.inputSerialNumber')" auto-complete="off" clearable
              @input="changeIpt($event)" />
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Custom.Number") }}
            </div>
            <el-input :disabled="type == 'edit'" v-model.trim="Serial.number" :placeholder="$t('Custom.NumberInput')"
              auto-complete="off" clearable @input="changeIpt($event)" />
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Warning.FrontVersion") }}
            </div>

            <el-select v-model="Serial.frontend_update_version" style="width: 100%"
              :placeholder="$t('Warning.SelectFront')">
              <el-option v-for="item in frontVersionList" :key="item.id" :value="item.id"
                :label="item.version"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Warning.ServiceVersion") }}
            </div>

            <el-select v-model="Serial.service_update_version" style="width: 100%"
              :placeholder="$t('Warning.SelectService')">
              <el-option v-for="item in backVersionList" :key="item.id" :value="item.id"
                :label="item.version"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Warning.MacAddress") }}
            </div>
            <el-input v-model.trim="Serial.mac_addr" :placeholder="$t('Warning.InputMacAddress')" auto-complete="off"
              clearable @input="changeIpt($event)" />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="btn-normal pan-btn blue-btn" style="margin-right: 0px; background: #4875ef"
          @click="handleConfirm(Serial)" :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }">
          {{ $t("Login.OK") }}
        </div>
        <div class="btn-normal pan-btn tiffany-btn" @click="dialogVisible = false" style="background: #767e95">
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>

    <el-dialog :title="$t('Custom.AdPower')" :visible.sync="dialogVisibles" width="364px" class="dialog">
      <el-form ref="workShopForm" label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Custom.AdPwd") }}
            </div>
            <el-input v-model.trim="password" :placeholder="$t('Custom.pwd')" auto-complete="off" type="password"
              clearable @input="changeIpt($event)" />
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="btn-normal pan-btn blue-btn" style="margin-right: 0px; background: #4875ef" @click="handlePwd()"
          :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }">
          {{ $t("Login.OK") }}
        </div>
        <div class="btn-normal pan-btn tiffany-btn" @click="dialogVisibles = false" style="background: #767e95">
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog>

    <!-- <el-dialog
      :title="$t('Machine.Edit')"
      :visible.sync="editDialogVisible"
      width="600px"
      class="dialog dialogEdit"
    >

      <el-form label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Custom.Number") }}
            </div>
            <el-input
              v-model="editForm.number"
              :placeholder="$t('Custom.NumberInput')"
              auto-complete="off"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>
      <el-form label-width="0px">
        <div class="form-box">
          <el-form-item prop="name">
            <div class="label" style="text-align: left">
              {{ $t("Custom.Serial") }}
            </div>
            <el-input
              v-model="editForm.serial_number"
              :placeholder="$t('Custom.serial_number')"
              auto-complete="off"
              clearable
            />
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <div
          class="btn-normal pan-btn blue-btn"
          style="margin-right: 0px; background: #4875ef"
          @click="handleConfirmEdit"
          :style="{ background: isBlueConfire ? '#3660DD' : '#4875ef' }"
        >
          {{ $t("Login.OK") }}
        </div>
        <div
          class="btn-normal pan-btn tiffany-btn"
          @click="editDialogVisible = false"
          style="background: #767e95"
        >
          {{ $t("AddMachine.Cancel") }}
        </div>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import "../../style/table.less";
import { getSerial, deleteSerial, addSerial } from "@/api/custom";
import { getVersionList, updateMacAndVersion } from "@/api/layout";

import { awaitWraper, isEmpty } from "@/js/util";
let titleConfig = ["AddMachine.AddEquipment", "Machine.Edit"];
export default {
  name: "Serial",
  data() {
    return {
      Startime: "",
      Endtime: "",
      dialogVisible: false,
      dialogVisibles: false,
      editDialogVisible: false, //编辑弹窗可视按钮
      groupName: "",
      workShopId: "",
      repeat: true,
      repeatTip: true,
      repeatSearch: true,
      password: "",
      type: "add",
      Serial: {
        name: "",
        number: "",
        mac_addr: "", //网卡地址
        frontend_update_version: "", //前端版本号
        service_update_version: "", //后端版本号
        id: "",
      },
      type: "",
      title: "",
      operateType: "",
      tableData: [],
      company_id: "",
      serial_number: "",
      expandRowKeys: [],
      isBlue: false,
      isBlueSearch: false,
      isBlueConfire: false,
      total: 0,
      page: 1,
      pagesize: 15,
      editForm: {},
      versionList: [],
      rules: {},
    };
  },
  created() {
    this.getSerial("", "type");
    this.getVersionList();
  },
  filters: {
    time(item) {
      const date = new Date(item * 1000);
      const month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      const day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      const hour =
        date.getHours() >= 10 ? date.getHours() : "0" + date.getHours();
      const minute =
        date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes();
      return (
        date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + minute
      );
    },
  },
  computed: {
    frontVersionList() {
      return this.versionList.filter(
        (item) => item.type == 1 && !isEmpty(item.version)
      );
    },
    backVersionList() {
      return this.versionList.filter(
        (item) => item.type == 2 && !isEmpty(item.version)
      );
    },
  },
  methods: {
    async getVersionList(number) {
      // showLoading
      const [err, res] = await awaitWraper(getVersionList({ pagesize: 99999}));
      //   closeLoading
      if (err) {
        return;
      }
      this.versionList = res.data.list;
    },
    changeIpt(e) {
      this.$forceUpdate();
    },
    reset() {
      this.isBlue = true;
      setTimeout(() => {
        this.isBlue = false;
      }, 500);
      this.groupName = "";
      this.getSerial("", "type");
    },
    handlePwd() {
      const params = {};
      params.company_id = this.company_id;
      params.serial_number = this.serial_number;
      params.password = this.$md5(this.password);
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      if (!this.repeatTip) {
        return;
      }
      this.repeatTip = false;
      setTimeout(() => {
        this.repeatTip = true;
      }, 2000);
      if (!this.password) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.pwd"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      deleteSerial(params, token, lang).then((response) => {
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: this.$t("Setting.RemovedSuccessfully"),
            type: "success",
            duration: 1000,
          });
          this.password = "";
          this.company_id = "";
          this.company_id = "";
          this.dialogVisibles = false;
          this.getSerial("", "type", "delete");
        }
      });
    },
    handleConfirmEdit() {
      //确认编辑
      console.log(this.editForm, "aaa");
    },
    handleConfirm(Serial) {
      this.isBlueConfire = true;
      setTimeout(() => {
        this.isBlueConfire = false;
      }, 500);
      const params = {};
      if (!this.repeat) {
        return;
      }
      this.repeat = false;
      setTimeout(() => {
        this.repeat = true;
      }, 2000);
      if (!this.Serial.name) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.SerialIpt"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.Serial.number) {
        this.$message({
          showClose: true,
          message: this.$t("Custom.NumberInput"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.Serial.frontend_update_version) {
        this.$message({
          showClose: true,
          message: this.$t("Warning.SelectFront"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      if (!this.Serial.service_update_version) {
        this.$message({
          showClose: true,
          message: this.$t("Warning.SelectService"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      params.number = Serial.number;
      params.serial_number = Serial.name;
      params.company_id = this.company_id;
      params.mac_addr = Serial.mac_addr;
      params.frontend_update_version = Serial.frontend_update_version;
      params.service_update_version = Serial.service_update_version;
      // if (this.type == "edit") {
      //   params.id = Serial.id;
      // }
      const token = localStorage.getItem("token");
      const lang = localStorage.getItem("language");
      if (this.type == "add") {
        addSerial(params, token, lang).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.AddSuccess"),
              type: "success",
              duration: 1000,
            });
          }
          this.dialogVisible = false;
          this.getSerial("", "type", "add");
        });
      } else {
        updateMacAndVersion({
          mac_addr: params.mac_addr,
          frontend_update_version: params.frontend_update_version,
          service_update_version: params.service_update_version,
          id: Serial.id,
        }).then((response) => {
          if (response.code === 200) {
            this.$message({
              showClose: true,
              message: this.$t("Tip.EditSuccess"),
              type: "success",
              duration: 1000,
            });
          }
          this.dialogVisible = false;
          this.getSerial("", "type", "add");
        });
      }
    },
    handleAdd(val) {
      this.dialogVisible = true;
      this.type = "add";
      this.title = titleConfig[0];
      for (let key of Object.keys(this.Serial)) {
        this.Serial[key] = "";
      }
      this.company_id = val.id;
    },
    handleEdit(index, row) {
      this.type = "edit";
      this.title = titleConfig[1];

      for (let key of Object.keys(this.Serial)) {
        if (key == "mac_addr") {
          this.Serial["mac_addr"] = row["mac"];
          continue;
        }
        if (key == "frontend_update_version") {
          console.log(this.frontVersionList);
          let target = this.frontVersionList.find(
            (item) => item.version == row["view_update_version"]
          );
          console.log(target);
          this.Serial["frontend_update_version"] = isEmpty(target)
            ? ""
            : target.id;
          continue;
        }
        if (key == "service_update_version") {
          let target = this.backVersionList.find(
            (item) => item.version == row["service_update_version"]
          );
          this.Serial["service_update_version"] = isEmpty(target)
            ? ""
            : target.id;
          continue;
        }
        this.Serial[key] = row[key];
      }
      this.company_id = row["company_id"];
      this.dialogVisible = true;
    },
    handleDeleteOrder(val) {
      this.company_id = val.company_id;
      this.serial_number = val.serial_number;
      this.dialogVisibles = true;
      this.dialogVisible = false;
    },
    searchGroup() {
      if (!this.repeatSearch) {
        return;
      }
      this.repeatSearch = false;
      setTimeout(() => {
        this.repeatSearch = true;
      }, 2000);
      this.isBlueSearch = true;
      setTimeout(() => {
        this.isBlueSearch = false;
      }, 500);
      this.getSerial(this.groupName, "", "");
    },
    async getSerial(val, type, item) {
      if (!val && !type) {
        this.$message({
          showClose: true,
          message: this.$t("Tip.Criteria"),
          type: "warning",
          duration: 1000,
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: this.$t("Tip.Loading"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {};
      params.page = this.page;
      params.pagesize = this.pagesize;
      params.serial_number = val;
      params.token = localStorage.getItem("token");
      params.language = localStorage.getItem("lang");
      await getSerial(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.count;
        this.noDataTable = this.total === 0;
        if (!item) {
          this.expandRowKeys = [];
        }
        if (val) {
          for (var i = 0; i < this.tableData.length; i++) {
            this.expandRowKeys.push(this.tableData[i].id);
          }
        }
      });
      loading.close();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.pagesize = val;
      this.getSerial("", "type");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSerial("", "type");
    },
    row_key(row) {
      return row.id;
    },
    expandChange(row, rowList) {
      this.expandRowKeys = [];
      rowList.forEach((item) => {
        this.expandRowKeys.push(item.id);
      });
    },
    rowClick(row, column, event) {
      if ([...event.target.classList].includes("OperationImg")) return;
      // eslint-disable-next-line no-extend-native
      Array.prototype.remove = function (val) {
        const index = this.indexOf(val);
        if (index > -1) {
          this.splice(index, 1);
        }
      };

      if (this.expandRowKeys.indexOf(row.id) < 0) {
        this.expandRowKeys.push(row.id);
      } else {
        this.expandRowKeys.remove(row.id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mgr {
  margin-right: 0px !important;
  margin-left: 20px;
}

@media (max-width: 1367px) {
  .machine_search_list {
    flex: 1.75 !important;
  }

  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1441px) {
  .machine_search_list {
    flex: 1.45 !important;
  }

  .dialog /deep/ .el-input__suffix {
    right: 20px !important;
  }
}

@media (min-width: 1441px) and (max-width: 1661px) {
  .machine_search_list {
    flex: 1.7 !important;
  }
}

@media (min-width: 1661px) and (max-width: 1919px) {
  .machine_search_list {
    flex: 1.6 !important;
  }
}

.pagination-container {
  margin: 20px 25px;
  float: left;
}

.dialog /deep/ .el-form-item__content {
  line-height: 35px;
  font-size: 18px;
}

.dialog /deep/ .el-dialog {
  border-radius: 20px !important;
  margin-top: 30vh !important;
  background: #e7e9ee;
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, 0.16));
}

.dialog /deep/ .el-dialog__body {
  padding: 0px 20px 10px 20px;
}

.btn-normal {
  width: 70px;
  height: 32px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 400;
  text-align: center;
  color: #fff;
  float: right;
  margin-left: 10px;
  cursor: pointer;
}

.dialog /deep/ .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}

.dialog /deep/ .plan-form.r3 .label {
  font-size: 16px;
}

.dialog /deep/ .el-input--suffix {
  width: 100% !important;
  padding-right: 15px;
}

.dialog /deep/ .plan-form .row .form-item {
  width: 33.4% !important;
}

.dialog /deep/ .el-dialog__title {
  font-weight: bold;
  font-size: 20px;
}

.dialog /deep/ .el-dialog__body {
  padding: 20px 20px;
}

.dialog /deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  text-align: left;
}

.dialog /deep/ .el-dialog__headerbtn {
  font-size: 30px !important;
  top: 12px;
}

.dialog /deep/ .el-input__suffix {
  right: 20px;
}

.dialog /deep/ .el-dialog__footer {
  padding: 0px 20px 15px !important;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.main {
  width: 100%;
  height: 100%;
  background-color: #ebecee;

  .app-wrapper {
    background-color: #ebecee;
    height: 100%;

    .table_content {
      height: calc(100vh - 200px);
      overflow: auto;

      /deep/ .el-table__body-wrapper {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        background-color: #ebecee;
      }

      /deep/ .el-table tr {
        // background-color: #ebecee !important;
        cursor: pointer;
      }
    }

    .machine_title {
      padding: 22px 20px;
      color: #414141;
      font-size: 24px;
      font-weight: 700;
      text-align: left;
    }

    .machine_search {
      display: flex;
      flex-direction: row;
      padding: 0px 25px;

      .machine_search_list {
        flex: 1.25;

        .search_title {
          float: left;
          text-align: left;
          height: 32px;
          line-height: 32px;
          margin: 10px 10px 10px 0;
          color: #4d4f5c;
          font-size: 18px;
        }

        .search_selected {
          float: left;
          margin: 10px 10px 10px 0;

          /deep/ .el-date-editor.el-input,
          /deep/ .el-date-editor.el-input__inner {
            width: 100%;
          }

          /deep/ .el-input__inner {
            height: 32px;
            line-height: 32px;
          }

          /deep/ .el-input__icon {
            margin-top: 5px;
            color: #a4afb7;
            font-weight: bold;
          }

          /deep/ .el-input__suffix-inner .el-input__icon {
            margin-top: -2px;
          }

          /deep/ .input-wrapper .el-input__icon {
            margin-top: 2px;
          }

          /deep/ .el-select .el-input .el-select__caret.is-reverse {
            margin-top: -3px;
          }
        }
      }

      .machine_search_btn {
        flex: 0.3;

        .search_btn {
          width: 70px;
          height: 31px;
          background: #4875ef;
          font-size: 13px;
          color: #f2f3f6;
          text-align: center;
          line-height: 31px;
          border-radius: 30px;
          margin-top: 12px;
          margin-right: 10px;
          cursor: pointer;
        }

        .edi_color {
          background-color: #767e95;
        }
      }

      .machine_search_space {
        flex: 5.4;
      }
    }
  }
}
</style>
